import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";

const AdminUserList = ({
  users,
  currentItems,
  handleEditClickOpen,
  handleDelete,
  formatDate,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortedItems = [...currentItems].sort((a, b) => {
    if (sortConfig.key === "last_login") {
      const dateA = new Date(a.last_login);
      const dateB = new Date(b.last_login);
      return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => requestSort("email")}
            >
              Email
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => requestSort("first")}
            >
              Name
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => requestSort("company")}
            >
              Company
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => requestSort("quota")}
            >
              Quota
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => requestSort("last_login")}
            >
              Active
            </TableCell>
            <TableCell
              style={{ cursor: "pointer" }}
              onClick={() => requestSort("admin")}
            >
              Admin
            </TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedItems.map((user) => (
            <TableRow
              key={user._id}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(192, 191, 191, 0.1)",
                  cursor: "pointer",
                },
              }}
            >
              <TableCell
                style={{ color: user.deleted ? "#bebdbd" : "inherit" }}
              >
                {user.email}
              </TableCell>
              <TableCell
                style={{ color: user.deleted ? "#bebdbd" : "inherit" }}
              >
                {user.first} {user.last}
              </TableCell>
              <TableCell
                style={{ color: user.deleted ? "#bebdbd" : "inherit" }}
              >
                {user.company}
              </TableCell>
              <TableCell
                style={{ color: user.deleted ? "#bebdbd" : "inherit" }}
              >
                {user.search_count}/{user.quota}
              </TableCell>
              <TableCell
                style={{ color: user.deleted ? "#bebdbd" : "inherit" }}
              >
                Login: {formatDate(user.last_login)}
                <br />
                Search: {formatDate(user.last_search_attempt)}
              </TableCell>
              <TableCell
                style={{ color: user.deleted ? "#bebdbd" : "inherit" }}
              >
                {user.admin ? "Yes" : "No"}
              </TableCell>
              <TableCell>
                <IconButton
                  style={{ color: user.deleted ? "#bebdbd" : "gray" }}
                  onClick={() => handleEditClickOpen(user._id)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  style={{ color: user.deleted ? "#bebdbd" : "gray" }}
                  onClick={() => handleDelete(user)}
                >
                  {user.deleted ? <Add /> : <Delete />}
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminUserList;
