import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Container,
  Box,
  List,
  InputBase,
  useMediaQuery,
  Tooltip,
  CircularProgress,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { searchService } from "../services/search.service";
import ProgressBar from "../components/progressBar";
import SearchIcon from "@mui/icons-material/Search";
import { utilsService } from "../services/utils";
import PaginationFormControl from "../components/pagination-control";
import { showErrorMsg } from "../services/event-bus.service";
import { useParams } from "react-router-dom";
import { StatusUpdatesContext } from "./../services/search.status.updates.context";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const ArchiveIndex = () => {
  const [allSearchIds, setAllSearchIds] = useState([]); // All IDs fetched from backend
  const [searchIds, setSearchIds] = useState([]); // IDs to display after filter
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const pageCount = Math.ceil(searchIds.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchIds.slice(indexOfFirstItem, indexOfLastItem);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const patentNumberParams = useParams().patentNumber;
  const [userInteracted, setUserInteracted] = useState(false);
  const { activeSearchesId } = useContext(StatusUpdatesContext);
  const { activeSearchesRecord } = useContext(StatusUpdatesContext);
  const [expandedItems, setExpandedItems] = useState({});
  const storageToken = localStorage.getItem("token");
  useEffect(() => {
    if (!storageToken) {
      navigate("/");
    }
  }, [storageToken]);
  useEffect(() => {
    if (!userInteracted && patentNumberParams) {
      setSearchTerm(patentNumberParams); // Just set the searchTerm, no need to trigger search here
    }
  }, [patentNumberParams]);

  // New useEffect to trigger search when searchTerm is updated
  useEffect(() => {
    handleSearch();
  }, [searchTerm, allSearchIds]);
  useEffect(() => {
    const userName = localStorage.getItem("userName");
    const userEmail = localStorage.getItem("userEmail");

    // Redirect to login if no user data is present
    if (!userName && !userEmail) {
      navigate("/");
    }
    handleGetUserResults();
  }, []);

  useEffect(() => {
    UpdateEnireDataStructureOfSearch();
    if (!searchIds || searchIds.length === 0) return;

    UpdateEnireDataStructureOfSearch();
    console.log(
      `Archive-index updating the active searches list: ${activeSearchesId}`
    );
  }, [activeSearchesId, activeSearchesRecord]);

  //When an update status of a search arrived from the server (Using the aearch.status.updates.context)
  //we need to update the list of all history items in the page.

  function UpdateEnireDataStructureOfSearch() {
    try {
      activeSearchesRecord.forEach((newItem) => {
        let index = allSearchIds.findIndex(
          (item) => item.search_id === newItem.search_id
        );
        if (index !== -1) {
          allSearchIds[index] = newItem;
        } else {
          allSearchIds.push(newItem);
        }

        index = searchIds.findIndex(
          (item) => item.search_id === newItem.search_id
        );
        if (index !== -1) {
          searchIds[index] = newItem;
        } else {
          searchIds.push(newItem);
        }
      });
    } catch (error) {
      console.error(
        "An error occurred in UpdateEnireDataStructureOfSearch:",
        error
      );
    }
  }

  const handleGetUserResults = async () => {
    setLoading(true);
    try {
      const fetchedSearchIds = await searchService.getUserResults("");
      console.log("fetchSearchIds", fetchedSearchIds);
      setAllSearchIds(fetchedSearchIds);
      setSearchIds(fetchedSearchIds);
    } catch (error) {
      console.error("Failed to fetch user results: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleSearch = () => {
    if (!allSearchIds.length) return; // Prevent search if there are no items

    setUserInteracted(true);

    // If search term is empty, reset to all search IDs
    if (searchTerm.trim() === "") {
      setSearchIds(allSearchIds); // Show all items
      setCurrentPage(1); // Reset to the first page
      return;
    }

    const searchTermLowerCase = searchTerm.toLowerCase();
    const filteredIds = allSearchIds.filter((pat) => {
      const patentNumber = pat.query.patent_number
        ? pat.query.patent_number.toLowerCase()
        : "";
      return patentNumber.includes(searchTermLowerCase);
    });

    setSearchIds(filteredIds);
    setCurrentPage(1);
  };

  const handleOpenPatent = async (archiveId, itemStatus, claimIndex) => {
    navigate(
      `/patent-products/${archiveId}?status=${itemStatus}&claimIndex=${claimIndex}`
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          marginTop: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress sx={{ color: "primary.main" }} />
        <Typography variant="h6" sx={{ marginTop: "10px", color: "gray" }}>
          Almost there, showing results of your search...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth="lg" sx={{ marginTop: "30px" }}>
        <Box
          sx={{
            position: "sticky",
            top: "100px",
            zIndex: "100",
            backgroundColor: "white",
            padding: "10px 0",
            borderBottom: "1px solid #ccc",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                position: "relative",
                borderRadius: "5px",
                marginLeft: "20px",
                width: "30%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  pointerEvents: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SearchIcon />
              </div>

              <InputBase
                placeholder="Search Archive Items"
                inputProps={{ "aria-label": "search" }}
                sx={{
                  color: "inherit",
                  paddingLeft: "40px",
                  width: "100%",
                  borderRadius: "5px",
                  fontSize: isSmallScreen ? "10px" : "16px",
                  position: "relative",
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
            {searchIds.length > 0 && (
              <Box display="flex" alignItems="center">
                <PaginationFormControl
                  itemsPerPage={itemsPerPage}
                  handleItemsPerPageChange={handleItemsPerPageChange}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                />
              </Box>
            )}
          </Box>
        </Box>

        {currentItems.length > 0 ? (
          <List sx={{ marginTop: "60px" }}>
            {currentItems.map((item, index) => (
              <Box
                className="item-box"
                key={index}
                style={{
                  padding: "15px 0",
                  borderBottom: "1px solid #e9e8e8",
                  ...(isSmallScreen
                    ? { display: "block" }
                    : { display: "flex" }),
                }}
              >
                {isSmallScreen && item.thumbnail && (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                      backgroundColor: "gray",
                    }}
                  >
                    <img
                      src={item.thumbnail}
                      alt="patent-img"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        display: "block",
                      }}
                    />
                  </div>
                )}
                {!isSmallScreen && item.thumbnail && (
                  <div
                    style={{
                      width: "120px",
                      height: "120px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "20px",
                      borderRadius: "5px",
                      border: "1px solid rgb(204, 204, 204, 0.2)",
                    }}
                  >
                    <img
                      src={item.thumbnail}
                      alt="patent-img"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                        display: "block",
                      }}
                    />
                  </div>
                )}
                {!item.thumbnail && (
                  <Box
                    sx={{
                      width: isSmallScreen ? "60%" : "80px",
                      height: isSmallScreen ? "320px" : "160px",
                      maxWidth: isSmallScreen ? "280px" : "80px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                      color: "gray",
                      border: "1px solid rgb(204, 204, 204, 0.2)",
                      borderRadius: "5px",
                      marginRight: isSmallScreen ? "auto" : "20px",
                      marginLeft: isSmallScreen ? "auto" : "0",
                      marginBottom: isSmallScreen ? "20px" : "none",
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "20px",
                    }}
                  >
                    <Typography variant="body2" sx={{ fontSize: "14px" }}>
                      No data
                    </Typography>
                  </Box>
                )}
                <Box
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  <Box
                    style={{
                      display: isSmallScreen ? "" : "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "primary.main", // Apply custom text color to the Link
                      }}
                      fontWeight="bold"
                    >
                      <span>{item.query.patent_number}</span>
                      {Object.values(item.claims || []).map((claim, index) =>
                        claim && claim.index ? (
                          <span
                            style={{ color: "lightgray", marginLeft: "5px" }}
                            key={claim.index}
                          >
                            |{" "}
                            <Tooltip
                              title={
                                !claim.status || claim.status === "completed"
                                  ? "Click to show results"
                                  : `${claim.status}`
                              }
                            >
                              <span
                                onClick={
                                  !claim.status || claim.status === "completed"
                                    ? () =>
                                        handleOpenPatent(
                                          item.search_id,
                                          item.status,
                                          claim.index
                                        )
                                    : null
                                }
                                style={{
                                  cursor:
                                    !claim.status ||
                                    claim.status === "completed"
                                      ? "pointer"
                                      : "not-allowed",
                                  textDecoration:
                                    !claim.status ||
                                    claim.status === "completed"
                                      ? "underline"
                                      : "none",
                                }}
                              >
                                <span
                                  style={{ fontSize: "11px", color: "gray" }}
                                >
                                  Claim {claim.index}
                                  {claim.status &&
                                    claim.status !== "completed" && (
                                      <ErrorOutlineIcon
                                        style={{
                                          fontSize: "14px",
                                          color: "red",
                                          marginLeft: "1px",
                                        }}
                                      />
                                    )}
                                </span>{" "}
                              </span>
                            </Tooltip>
                          </span>
                        ) : null
                      )}
                    </Typography>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="body2"
                        sx={{ color: "gray" }}
                        style={{ marginRight: "8px" }}
                      >
                        ( {utilsService.formatDate(item.updated_at)} )
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color:
                            item.status === "completed"
                              ? "green"
                              : item.status === "running"
                              ? "rgb(25, 118, 210)" // Blue for running
                              : item.status === "failed"
                              ? "red" // Red for failed
                              : "black", // Black for other statuses
                        }}
                      >
                        {item.status === "running" ? (
                          <>
                            {item.status} <CircularProgress size={15} />
                          </>
                        ) : (
                          item.status
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "gray",
                      display: "-webkit-box",
                      WebkitLineClamp: expandedItems[index] ? "none" : 2, // Control the number of lines
                      WebkitBoxOrient: "vertical",
                      overflow: expandedItems[index] ? "visible" : "hidden", // Hide overflow if not expanded
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.description}
                  </Typography>

                  {/* Button to toggle description */}
                  <Box sx={{ mt: 1 }}>
                    {item.description && (
                      <Typography
                        onClick={() => {
                          console.log("Button clicked for index:", index); // Log for debugging
                          setExpandedItems((prev) => ({
                            ...prev,
                            [index]: !prev[index], // Toggle the current item
                          }));
                        }}
                      >
                        {expandedItems[index]
                          ? "Close Abstract"
                          : "Show Abstract..."}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </List>
        ) : (
          <Typography variant="h6" textAlign="center" sx={{ mt: 2 }}>
            No search history found.
          </Typography>
        )}
      </Container>
    </>
  );
};

export default ArchiveIndex;
