import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Box,
} from "@mui/material";

const ConfirmCreditForSearch = ({
  credit,
  searchAvailable,
  onConfirm,
  onClose,
  selectedClaim,
}) => {
  const remainingCredit = searchAvailable - credit;
  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="confirm-credit-dialog-title"
      aria-describedby="confirm-credit-dialog-description"
    >
      <DialogTitle id="confirm-credit-dialog-title">Confirm Credit</DialogTitle>
      <DialogContent>
        {remainingCredit >= 0 ? (
          <DialogContentText id="confirm-credit-dialog-description">
            Please confirm your search action. This will use your credits.
          </DialogContentText>
        ) : (
          <DialogContentText
            id="confirm-credit-dialog-description"
            color="error"
          >
            You don't have enough credits to perform this search.
          </DialogContentText>
        )}
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            <strong>Search Credit:</strong> {credit}
          </Typography>
          <Typography variant="body1">
            <strong>Your Available Searches :</strong> {searchAvailable}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {remainingCredit >= 0 ? (
          <Button onClick={onConfirm} color="primary" variant="contained">
            Confirm
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCreditForSearch;
