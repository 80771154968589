import { showErrorMsg, showSuccessMsg } from "./event-bus.service";

const BASE_URL = process.env.REACT_APP_API_URL;


export async function getUsers() {
  try {
    const response = await fetch(`${BASE_URL}/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`${errorText.error}: ${response.status}`);
      throw new Error(`${errorText.error}: ${response.status}`);
    }

    const data = await response.json();
    console.log("USERS response", data);
    return data;

  } catch (error) {

    showErrorMsg(`Failed to fetch users: ${error.message}`);
    throw error
  }
}
export async function createUser(user) {
  try {
    console.log("user add ", user);
    const response = await fetch(`${BASE_URL}/create_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(user)
    });

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to create user: ${errorText.error}`);
      throw new Error(`Failed to create user: ${errorText.error}`);
    }

    const data = await response.json();
    console.log("create user", data);
    showSuccessMsg('User created successfully');
    return data;

  } catch (error) {
    showErrorMsg(`Failed to create user: ${error.message}`);
    throw error;
  }
}
export async function updateUser(user) {
  try {
    const response = await fetch(`${BASE_URL}/update_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(user)
    });

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to update user: ${errorText.error}`);
      throw new Error(`Failed to update user: ${errorText.error}`);
    }

    const data = await response.json();
    showSuccessMsg('User updated successfully');
    return data;

  } catch (error) {
    showErrorMsg(`Failed to update user: ${error.message}`);
    throw error;
  }
}

export async function deleteUser(user) {
  try {
    const response = await fetch(`${BASE_URL}/update_user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(user)
    });

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to delete user: ${errorText.error}`);
      throw new Error(`Failed to delete user: ${errorText.error}`);
    }

    const data = await response.json();
    showSuccessMsg(user.deleted ? "User deleted successfully" : "User added successfully");
    return data;

  } catch (error) {
    showErrorMsg(`Failed to update user: ${error.message}`);
    throw error;
  }
}

export async function getLoggedInUser(navigate) {
  try {
    const response = await fetch(`${BASE_URL}/user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (response.status === 401) {
      showErrorMsg('Unauthorized access, please log in again.');
      throw new Error('Unauthorized access, please log in again.');
    }

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to fetch logged in user: ${errorText.error}`);
      throw new Error(`Failed to fetch logged in user: ${errorText.error}`);
    }

    const data = await response.json();
    // showSuccessMsg('Logged in user data fetched successfully');
    return data;

  } catch (error) {
    showErrorMsg(`Failed to fetch logged in user: ${error.message}`);
    navigate('/');
    throw error;
  }
}
const updateUserAgreement = async (agreementVersion) => {
  try {
    const response = await fetch(`${BASE_URL}/user/agreement`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ agreement: agreementVersion })
    });

    console.log('Response:', response);

    const data = await response.json();
    console.log('Response Data:', data);

    return data;
  } catch (error) {
    showErrorMsg(`Failed to update user agreement: ${error.message}`);
    throw error;
  }
};


export async function generateBillingReport(data) {
  console.log("data user csv", data);
  try {
    const response = await fetch(`${BASE_URL}/user_search_stats`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to generate billing report: ${errorText.error}`);
      throw new Error(`Failed to generate billing report: ${errorText.error}`);
    }

    const jsonData = await response.json();

    // Convert JSON to CSV
    const csvData = convertJsonToCsv(jsonData);

    // Generate a dynamic file name based on dates, user_id, or company
    const fileName = generateFileName(data);

    // Create CSV Blob and trigger download
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.csv`;  // Use generated file name
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    showSuccessMsg('Billing report generated successfully');

  } catch (error) {
    showErrorMsg(`Failed to generate billing report: ${error.message}`);
    throw error;
  }
}

// Helper function to convert JSON to CSV
function convertJsonToCsv(jsonData) {
  const csvRows = [];

  // Add the header row
  csvRows.push("email,user_id,created_at,count");

  // Loop through the JSON data and create CSV rows
  jsonData.forEach(item => {
    const email = item._id.email || ''; // Use empty string if email is missing
    const userId = item._id.user_id;
    const createdAt = item._id.created_at;
    const count = item.count;
    csvRows.push(`${email},${userId},${createdAt},${count}`);
  });

  // Join all the rows with a newline character to form the CSV string
  return csvRows.join('\n');
}

// Helper function to generate the file name
function generateFileName(data) {
  const { user_id, company, date_from, date_to } = data;
  let fileNameParts = ['billing'];

  if (date_from) fileNameParts.push(date_from);
  if (date_to) fileNameParts.push(date_to);

  if (user_id) {
    fileNameParts.push(user_id);
  } else if (company) {
    fileNameParts.push(company);
  }

  return fileNameParts.join('_') || 'billing';  // If no data, fallback to 'billing_report'
}


export const userService = {

  getUsers,
  getLoggedInUser,
  createUser,
  updateUser,
  deleteUser,
  updateUserAgreement, generateBillingReport



};