import ReactGA4 from "react-ga4";

//test
const initializeGoogleAnalytics = () => {
  const tracking_id = process.env.REACT_APP_GOOGLE_ANALITICS_TRACKING_ID;
  if (!tracking_id) {
    throw new Error("The environment variable REACT_APP_GOOGLE_ANALITICS_TRACKING_ID is missing from the .env file, please add the var and recompile the code.");
  }
  ReactGA4.initialize(tracking_id);
  console.log("GA INITIALIZED");
};

const EVENT_CATEGORIES = {
  USER_INTERACTION: 'User Interaction',
  BUTTON_CLICK: 'Button Click',
  PATENT_SEARCH_START: 'Patent search',
  FORM_SUBMISSION: 'Form Submission',
  // Add more categories as needed.
};

const trackGoogleAnalyticsEvent = (category, action,) => {
  
  
  if (!Object.values(EVENT_CATEGORIES).includes(category)) {
    throw new Error(`Invalid category: ${category}. Must be one of ${Object.values(EVENT_CATEGORIES).join(', ')}`);
  }
  
  
  console.log("GA event = ", "category :" , category, ":", "action :", action);

  ReactGA4.event({
    category: category,
    action: action,
  });
};

const trackPageView = (page) => {
    console.log("Tracking page view for:", page);
    ReactGA4.send({ hitType: "pageview", page: page });
  };


export default initializeGoogleAnalytics;
export { initializeGoogleAnalytics, trackGoogleAnalyticsEvent, trackPageView, EVENT_CATEGORIES};

