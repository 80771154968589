import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Pagination, Hidden, useMediaQuery } from '@mui/material';

const PaginationFormControl = ({ itemsPerPage, handleItemsPerPageChange, pageCount, currentPage, handleChangePage }) => {
  const isLargeScreen = useMediaQuery('(min-width:800px)');

  if (!isLargeScreen) {
    return null; // If screen size is smaller than 800px, do not render pagination
  }

  return (
    <Hidden smDown>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" sx={{ width: 120, marginRight: 2 }}>
          <InputLabel id="items-per-page-label">Per Page</InputLabel>
          <Select
            labelId="items-per-page-label"
            id="items-per-page-select"
            value={itemsPerPage}
            label="Per Page"
            onChange={handleItemsPerPageChange}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={24}>24</MenuItem>
            <MenuItem value={96}>96</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handleChangePage}
          boundaryCount={1}
          siblingCount={1}
        />
      </div>
    </Hidden>
  );
};

export default PaginationFormControl;
