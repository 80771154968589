import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initializeGoogleAnalytics } from './services/analytics.service';

const client_Id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

initializeGoogleAnalytics();
console.log("client_Id", client_Id);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <GoogleOAuthProvider clientId={client_Id}><App /></GoogleOAuthProvider>



);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
