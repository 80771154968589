import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { StatusUpdatesProvider } from './services/search.status.updates.context'
import Navbar from './components/navbar';
import Login from './pages/login';
import NotFound from './pages/not-found';
import ArchiveIndex from './pages/archive-index';
import AdminIndex from './pages/admin-index';
import { UserMsg } from './components/user-msg';

import { trackPageView } from './services/analytics.service';


import Home from './pages/home';
import PrePatentProductSearch from './pages/pre-patent-product-search';
import Footer from './components/footer';
import PatentProductIndex from './pages/patent-product-index';

const theme = createTheme({
  typography: {
    fontFamily: 'Aleo, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#2393d8',
    },
    customTextColor: {
      main: '#ee1b25', // red color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2393d8', // Use customTextColor.main when focused
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#2393d8', // Custom color for the dropdown arrow when focused
        },
      },
    },
  },
});

//Making sure to send to google analitics the current page location - in every page change
const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);
};


function App() {
  const [userHasAgreed, setUserHasAgreed] = useState(false);
  const handleAgreement = () => {
    setUserHasAgreed(true);
  }
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <StatusUpdatesProvider>
          <div className="App">
            <Navbar />
            <UserMsg />

            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/home" element={<Home onAgree={handleAgreement} />} />
              <Route path="/patent-search" element={<PrePatentProductSearch />} />

              <Route path="/archive" element={<ArchiveIndex />} />
              <Route path="/archive/:patentNumber" element={<ArchiveIndex />} />


              <Route path="patent-products/:archiveId" element={<PatentProductIndex />} />
              <Route path="/admin" element={<AdminIndex />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </StatusUpdatesProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
