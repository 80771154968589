import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Link,
  Button,
  IconButton,
} from "@mui/material";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import PatentProductRankingDetail from "./patent-product-ranking-detail";
import { RxHamburgerMenu } from "react-icons/rx";
import PatentAnalysisMenu from "./patent-analyst-menu";

const PatentProductList = ({
  currentItems,
  archiveId,
  dataSearchId,
  handleFeedbackUpdate,
  choosenClaimIndex,
  claimsIndexes,
  loading,
}) => {
  const [selectedPatentProduct, setSelectedPatentProduct] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openRelevancyAnalysisMenu = Boolean(anchorEl);
  console.log("claimsIndexes****", claimsIndexes);
  const handleOpenRelevancyAnalysisMenu = (event, patent) => {
    setAnchorEl(event.currentTarget); // Set the anchor to the hamburger icon
    setSelectedPatentProduct(patent);
  };

  const handleCloseRelevancyAnalysisMenu = () => {
    setAnchorEl(null);
    setSelectedPatentProduct(null);
  };

  // Ensure currentItems is an array
  const items = currentItems || [];

  return (
    <>
      <Grid container spacing={3} style={{ padding: "70px 5px" }}>
        {items.map((patent, index) => (
          <Grid item xs={12} sm={6} md={4} key={patent.uid}>
            <Card
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <div style={{ flex: "1 0 auto", padding: "4px 12px 8px 12px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt="Favicon"
                      src={patent.favicon}
                      variant="rounded"
                      sx={{ width: 36, height: 36, margin: "10px 3px" }}
                    />
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      textTransform="lowercase"
                      sx={{ fontWeight: "bold" }}
                    >
                      {patent.source}
                    </Typography>{" "}
                  </Box>
                  <Box
                    color="primary"
                    variant="outlined"
                    sx={{
                      marginBottom: "5px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "30px",
                      height: "30px",
                      minWidth: "0",
                      padding: "0",
                      overflow: "hidden",
                      backgroundColor:
                        patent.rating_auto >= 0.75
                          ? "rgba(255, 0, 0, 0.5)" // high for 0.75 and above
                          : patent.rating_auto >= 0.25
                          ? "rgb(255, 255, 0)" // med for 0.25 to 0.74
                          : "rgba(35, 147, 216, 0.5)", // low for below 0.25
                      "&.Mui-disabled": {
                        color: "black", // Overrides the default disabled text color
                        borderColor: "transparent", // Remove border on disabled
                      },
                      "&:hover .hover-text": {
                        visibility: "visible",
                      },
                    }}
                  >
                    <Typography
                      className="hover-text"
                      fontSize="9px"
                      sx={{
                        lineHeight: "normal",
                        visibility: "hidden",
                      }}
                    >
                      {patent.rating_auto >= 0.75
                        ? "High"
                        : patent.rating_auto >= 0.25
                        ? "Med"
                        : "Low"}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "uppercase",
                    fontSize: "16px",
                    marginBottom: "5px",
                  }}
                >
                  <Link
                    href={patent.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "primary.main", // Apply custom text color to the Link
                    }}
                    underline="none"
                    style={{
                      textTransform: "uppercase",
                      fontSize: "16px",
                      marginBottom: "5px",
                    }}
                  >
                    {patent.title}
                  </Link>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    marginTop: "4px",
                    overflow: "hidden",
                    maxHeight: "3em",
                    textOverflow: "ellipsis",
                    lineHeight: "1.5em",
                  }}
                >
                  {patent.snippet}
                </Typography>
              </div>
              <div>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ minHeight: "20px ", padding: "0px 12px 5px 12px" }}
                >
                  <IconButton
                    sx={{ padding: "2px !important", margin: "2px" }}
                    onClick={() =>
                      handleFeedbackUpdate(
                        archiveId || dataSearchId,
                        patent.uid,
                        patent.rating === -1 ? 0 : -1
                      )
                    }
                  >
                    <AiOutlineDislike
                      color={patent.rating === -1 ? "red" : "gray"}
                      className="robot-icon"
                      size="24"
                    />
                  </IconButton>

                  <IconButton
                    sx={{ padding: "2px !important" }}
                    onClick={(event) =>
                      handleOpenRelevancyAnalysisMenu(event, patent)
                    }
                  >
                    <RxHamburgerMenu size="22" color="gray" />
                  </IconButton>

                  <IconButton
                    sx={{ padding: "2px !important", margin: "2px" }}
                    onClick={() =>
                      handleFeedbackUpdate(
                        archiveId || dataSearchId,
                        patent.uid,
                        patent.rating === 1 ? 0 : 1
                      )
                    }
                  >
                    <AiOutlineLike
                      color={patent.rating === 1 ? "blue" : "gray"}
                      className="robot-icon"
                      size="24"
                    />
                  </IconButton>
                </Box>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      <PatentAnalysisMenu
        anchorEl={anchorEl}
        open={openRelevancyAnalysisMenu}
        handleClose={handleCloseRelevancyAnalysisMenu}
        patentProduct={selectedPatentProduct}
        searchId={dataSearchId}
        choosenClaimIndex={choosenClaimIndex}
      />
    </>
  );
};

// Default props to handle undefined props
PatentProductList.defaultProps = {
  currentItems: [],
  archiveId: "",
  dataSearchId: "",
  handleRatingUpdate: () => {},
};

export default PatentProductList;
