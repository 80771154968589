import React from "react";
import { Box, Typography, Link, Button, useMediaQuery } from "@mui/material";
import SwipeableTextMobileStepper from "./carousel";
import { utilsService } from "../../services/utils";
import DownloadIcon from "@mui/icons-material/Download";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DescriptionIcon from "@mui/icons-material/Description";
const PatentInfo = ({
  patentInfo,
  querySearch,
  toggleCarouselVisibility,
  isCarouselVisible,
  updatedAt,
  status,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isLargeScreen = useMediaQuery("(min-width:960px)");
  if (patentInfo.title) console.log("patent info");
  const [showAllDescription, setShowAllDescription] = React.useState(false);

  const toggleDescription = () => {
    setShowAllDescription((prev) => !prev);
  };
  return (
    <Box
      sx={{
        margin: "50px 0 15px 0",
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: isSmallScreen ? "0" : "10px",
          marginBottom: isSmallScreen ? "20px" : "0",
        }}
      >
        {patentInfo?.images?.length > 0 && (
          <img
            src={patentInfo.images[0].full}
            alt="Patent Thumbnail"
            style={{
              width: isSmallScreen ? "100%" : "100px",
              maxWidth: "180px",
              height: isSmallScreen ? "auto" : "100px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        )}
      </Box>
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {patentInfo && Object.keys(patentInfo).length > 0 && (
              <Typography
                variant="h6"
                sx={{
                  fontSize: isSmallScreen ? "12px" : "14px",
                  fontWeight: "bold",
                  marginBottom: isSmallScreen ? "10px" : "0",
                  cursor: "pointer",
                }}
              >
                <Link
                  href={patentInfo.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                  sx={{
                    color: "primary.main", // Apply custom text color to the Link
                  }}
                >
                  {querySearch}
                </Link>
              </Typography>
            )}
          </Box>
          <Typography
            sx={{
              fontSize: isSmallScreen ? "10px" : "12px",
              marginBottom: isSmallScreen ? "10px" : "0",
            }}
          >
            {patentInfo && (
              <span style={{ marginRight: "5px" }}>
                {updatedAt &&
                  utilsService.formatDateWithHourAndMinute(updatedAt)}{" "}
                <span
                  style={{
                    color: "green",
                    marginLeft: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {status}
                </span>
              </span>
            )}
          </Typography>
        </Box>
        {/* detalis and description and images buttons */}
        {patentInfo?.publication_date && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Current Assignee:{" "}
                {patentInfo?.assignee?.length && (
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      fontWeight: "medium",
                      color: "text.primary",
                      fontSize: "12px",
                    }}
                  >
                    {patentInfo.assignee[0]}
                  </Typography>
                )}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Publication Date:{" "}
                {patentInfo?.publication_date && (
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{
                      fontWeight: "medium",
                      color: "text.primary",
                      fontSize: "12px",
                    }}
                  >
                    {utilsService.formatDateWithoutTime(
                      patentInfo.publication_date
                    )}
                  </Typography>
                )}
              </Typography>
            </Box>
            {/* description and images buttons */}
            <Box sx={{ display: "flex", gap: "10px", mt: "5px" }}>
              {patentInfo?.description && (
                <Button
                  startIcon={<DescriptionIcon />}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={toggleDescription}
                  sx={{ textTransform: "none" }}
                >
                  {showAllDescription ? "Close Abstract" : "Show Abstract"}
                </Button>
              )}
              {patentInfo?.images && (
                <Button
                  variant="outlined"
                  startIcon={<InsertPhotoIcon />}
                  color="primary"
                  size="small"
                  onClick={toggleCarouselVisibility}
                  sx={{ textTransform: "none" }}
                >
                  {isCarouselVisible ? "Close Images" : "Show Images"}
                </Button>
              )}
            </Box>
          </Box>
        )}
        <Typography
          variant="h6"
          sx={{
            fontSize: isSmallScreen ? "10px" : "12px",
            color: "gray",
            mt: isSmallScreen ? "10px" : "0",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: showAllDescription ? "none" : 2, // Limit to 3 lines when collapsed
            whiteSpace: "normal",
          }}
        >
          {patentInfo?.description}
        </Typography>

        {/* Render carousel if visible */}
        {isCarouselVisible && patentInfo?.images && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <SwipeableTextMobileStepper images={patentInfo.images} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PatentInfo;
