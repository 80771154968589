import React, { useEffect, useState, useContext } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { userService } from "../services/user.service";
import { StatusUpdatesContext } from "./../services/search.status.updates.context";

const RedLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: "pink",
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundColor: "red",
  },
}));

function LinearGauge() {
  //const [loggedInUser, setLoggedInUser] = useState({});
  //const [value, setValue] = useState(0);

  const { loggedInUser } = useContext(StatusUpdatesContext);
  const { userSearchTotalQuota } = useContext(StatusUpdatesContext);
  const { userSearchLeftsQuota } = useContext(StatusUpdatesContext);

  const percentage = (userSearchLeftsQuota / userSearchTotalQuota) * 100;

  /* useEffect(() => {
    fetchLoggedInUser();
  }, []);

  const fetchLoggedInUser = async () => {
    try {
      const loggedIn = await userService.getLoggedInUser();
      setLoggedInUser(loggedIn);
      if (loggedIn) {
        setValue((loggedIn.search_count / loggedIn.quota) * 100);
      } else {
        setValue(0);
      }
    } catch (error) {
      console.error("Failed to load logged-in user:", error);
    }
  };*/

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Box width="50%" mr={1}>
          {loggedInUser && (
            <RedLinearProgress variant="determinate" value={percentage} />
          )}
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color={"text.primary"}>
            {userSearchLeftsQuota} / {userSearchTotalQuota}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default function LinearProgressBar() {
  return (
    <Box mr={3} width="250px">
      <LinearGauge />
    </Box>
  );
}
