import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { userService } from "../../../services/user.service";

const validationSchema = Yup.object()
  .shape({
    selectedUser: Yup.string(),
    selectedCompany: Yup.string(),
    dateFrom: Yup.date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .required("Please select a 'Date From'"),
    dateTo: Yup.date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .required("Please select a 'Date To'")
      .when("dateFrom", (dateFrom, schema) => {
        return dateFrom && schema.isValidSync(dateFrom)
          ? schema.min(dateFrom, "'Date To' must be later than 'Date From'")
          : schema;
      }),
  })
  .test(
    "user-or-company",
    "Please select either a user or a company.",
    function (values) {
      return !!values.selectedUser || !!values.selectedCompany;
    }
  );

// Custom hook for form state logging
const useFormStateLogger = () => {
  const { values, errors, isValid, dirty } = useFormikContext();

  useEffect(() => {
    console.log("Form State:", { values, errors, isValid, dirty });
  }, [values, errors, isValid, dirty]);
};

// FormContent component to use the custom hook
const FormContent = ({ allUsers, error, isLoading, onClose }) => {
  useFormStateLogger();
  const { values, errors, touched, setFieldValue, isValid } =
    useFormikContext();

  const isSubmitDisabled =
    isLoading ||
    !(values.selectedUser || values.selectedCompany) ||
    !values.dateFrom ||
    !values.dateTo;

  return (
    <>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box display="flex" flexDirection="column" gap={2}>
          {/* Select User */}
          <FormControl fullWidth sx={{ marginTop: "10px" }}>
            <InputLabel id="user-select">Select User</InputLabel>
            <Select
              name="selectedUser"
              labelId="user-select"
              value={values.selectedUser}
              onChange={(e) => {
                setFieldValue("selectedUser", e.target.value);
                setFieldValue("selectedCompany", "");
              }}
              label="Select User"
              disabled={!!values.selectedCompany}
            >
              <MenuItem value="">Select User</MenuItem>
              {allUsers
                ?.filter((user) => user.first || user.last)
                .map((user) => (
                  <MenuItem key={user._id} value={user._id}>
                    {user.first} {user.last}
                  </MenuItem>
                ))}
            </Select>
            {errors.selectedUser && touched.selectedUser && (
              <Typography color="error">{errors.selectedUser}</Typography>
            )}
          </FormControl>

          {/* Select Company */}
          <FormControl fullWidth>
            <InputLabel id="user-company">Company</InputLabel>
            <Select
              name="selectedCompany"
              labelId="user-company"
              value={values.selectedCompany}
              onChange={(e) => {
                setFieldValue("selectedCompany", e.target.value);
                setFieldValue("selectedUser", "");
              }}
              label="Company"
              disabled={!!values.selectedUser}
            >
              <MenuItem value="">Select Company</MenuItem>
              {Array.from(new Set(allUsers?.filter(user => user.company).map(user => user.company))).map((company) => (
                <MenuItem key={company} value={company}>
                  {company}
                </MenuItem>
              ))}
            </Select>
            {errors.selectedCompany && touched.selectedCompany && (
              <Typography color="error">{errors.selectedCompany}</Typography>
            )}
          </FormControl>

          {/* Date From Input */}
          <TextField
            label="Date From"
            value={values.dateFrom ? values.dateFrom.toLocaleDateString() : ""}
            onClick={() => setFieldValue("showFromPicker", true)}
            fullWidth
            readOnly
          />
          {values.showFromPicker && (
            <DayPicker
              mode="single"
              selected={values.dateFrom}
              onSelect={(date) => {
                setFieldValue("dateFrom", date);
                setFieldValue("showFromPicker", false);
                setFieldValue("dateTo", null);
              }}
            />
          )}
          {errors.dateFrom && touched.dateFrom && (
            <Typography color="error">{errors.dateFrom}</Typography>
          )}

          {/* Date To Input */}
          <TextField
            label="Date To"
            value={values.dateTo ? values.dateTo.toLocaleDateString() : ""}
            onClick={() => setFieldValue("showToPicker", true)}
            fullWidth
            readOnly
          />
          {values.showToPicker && (
            <DayPicker
              mode="single"
              selected={values.dateTo}
              disabled={[{ before: values.dateFrom || new Date() }]}
              onSelect={(date) => {
                setFieldValue("dateTo", date);
                setFieldValue("showToPicker", false);
              }}
            />
          )}
          {errors.dateTo && touched.dateTo && (
            <Typography color="error">{errors.dateTo}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="contained" type="submit" disabled={isSubmitDisabled}>
          {isLoading ? <CircularProgress size={24} /> : "Generate CSV"}
        </Button>
      </DialogActions>
    </>
  );
};

const AdminBillingCreateEdit = ({ open, onClose, allUsers }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatDate = (date) => {
    return date ? date.toISOString().split("T")[0] : "";
  };

  const handleGenerateCSV = async (formattedData) => {
    setIsLoading(true);
    setError(null);
    try {
      await userService.generateBillingReport(formattedData);
      onClose(); // Close the dialog after successful generation
    } catch (error) {
      console.error("Error generating billing report:", error);
      setError(
        error.message || "An error occurred while generating the report."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Billing Report</DialogTitle>
      <Formik
        initialValues={{
          selectedUser: "",
          selectedCompany: "",
          dateFrom: null,
          dateTo: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const formattedData = {
            date_from: formatDate(values.dateFrom),
            date_to: formatDate(values.dateTo),
          };

          if (values.selectedUser) {
            formattedData.user_id = values.selectedUser;
          } else {
            formattedData.company = values.selectedCompany;
          }

          handleGenerateCSV(formattedData);
        }}
      >
        <Form>
          <FormContent
            allUsers={allUsers}
            error={error}
            isLoading={isLoading}
            onClose={onClose}
          />
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AdminBillingCreateEdit;
