// validation.js

class PatentValidator {
  constructor() {
    this.individualPattern = /^[A-Z]{2}[0-9]{1,12}(?:B[12]|C1)$/;
  }

  // Function to split the patent string based on various separators (comma, semicolon, whitespace)
  splitPatentString(patentString) {
    return patentString
      .split(/[,\s;]+/)
      .map((pat) => pat.trim())
      .filter((pat) => pat);
  }

  // Function to remove duplicates while preserving the original order
  removeDuplicates(patents) {
    return Array.from(new Set(patents));
  }

  // Function to validate and iterate over valid patents and flag invalid ones
  validatePatents(patentString) {
    const patents = this.removeDuplicates(this.splitPatentString(patentString.trim()));

    const validPatents = [];
    const invalidPatents = [];

    patents.forEach((patent) => {
      if (this.individualPattern.test(patent)) {
        validPatents.push(patent);
      } else {
        invalidPatents.push(patent);
      }
    });


    return { validPatents, invalidPatents };
  }
}

export default new PatentValidator();