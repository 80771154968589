import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import LinearProgressBar from "./linearProgress";
import { userService } from "../services/user.service";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({});
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    fetchLoggedInUser();
  }, []);

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    if (userName) {
      fetchLoggedInUser();
    } else {
      setLoggedInUser({});
    }
  }, [location.pathname]);

  const fetchLoggedInUser = async () => {
    try {
      const loggedIn = await userService.getLoggedInUser(navigate);
      setLoggedInUser(loggedIn);
    } catch (error) {
      console.error("Failed to load logged-in user:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("token");
    localStorage.removeItem("admin");
    setLoggedInUser({});
    navigate("/");
  };

  const userName = localStorage.getItem("userName");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    !isHomePage && (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #EC1C24",
            borderRadius: "20px",
            width: "96%",
            maxWidth: "1440px",
            padding: "10px 0",
            position: "fixed",
            top: "10px",
            left: 0,
            right: 0,
            margin: "auto",
            boxShadow: "2px 12px 8px 4px rgba(37, 37, 37, 0.3)",
            zIndex: 1000,
          }}
        >
          <Toolbar>
            {isMediumScreen && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon color="customTextColor" />
              </IconButton>
            )}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
            >
              <img
                src="/image.png"
                alt="LOGO_PioneerIP"
                style={{ height: "40px", marginRight: "150px" }}
              />
              {!isMediumScreen && (
                <>
                  <NavLink
                    to="/home"
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-link-active" : "nav-link"
                    }
                  >
                    Home
                  </NavLink>
                  {loggedInUser?.admin && (
                    <NavLink
                      to="/admin"
                      className={({ isActive }) =>
                        isActive ? "nav-link nav-link-active" : "nav-link"
                      }
                    >
                      Admin
                    </NavLink>
                  )}
                  <NavLink
                    to="/archive"
                    className={({ isActive }) =>
                      isActive ? "nav-link nav-link-active" : "nav-link"
                    }
                  >
                    History
                  </NavLink>
                </>
              )}
            </Typography>
            {userName && (
              <Box
                sx={{
                  marginRight: "10px",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color={"text.primary"}
                  sx={{
                    padding: "5px",
                    marginRight: "30px",
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  {userName}
                </Typography>
                <Box>
                  <LinearProgressBar value={50} />
                </Box>
              </Box>
            )}
            {!isMediumScreen && (
              <Button
                color="customTextColor"
                variant="outlined"
                onClick={handleLogout}
                sx={{
                  padding: "10px",
                  fontWeight: "bold",
                  borderRadius: "20px",
                }}
              >
                Logout
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {isMediumScreen && (
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <List>
              <ListItem
                button
                component={NavLink}
                to="/archive"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Archive" />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/patent-products"
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary="Patent" />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Drawer>
        )}
      </Box>
    )
  );
};

export default Navbar;
