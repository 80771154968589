import React, { useEffect, useRef, useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Box, Typography, Button } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { userService } from "../services/user.service";
import { msalInstance, initializeMsal } from "./msalConfig";

const BASE_URL = process.env.REACT_APP_API_URL;

const Login = () => {
  const navigate = useNavigate();
  const [triggerGoogleLogin, setTriggerGoogleLogin] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      await initializeMsal();
    };

    initialize();
  }, []);

  const handleGoogleSuccess = async (credentialResponse) => {
    const googleToken = credentialResponse.credential;
    const decodedGoogleToken = jwtDecode(googleToken);

    const { name, email } = decodedGoogleToken;
    console.log("Decoded JWT:", decodedGoogleToken);
    console.log("Name:", name);
    console.log("Email:", email);

    try {
      const response = await fetch(`${BASE_URL}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: googleToken, provider: "google" }),
      });

      if (!response.ok) {
        throw new Error("Failed to authenticate with the backend");
      }

      const data = await response.json();
      const customJwt = data.jwt;
      const admin = data.admin;

      localStorage.setItem("token", customJwt);
      localStorage.setItem("userName", name);
      localStorage.setItem("userEmail", email);
      localStorage.setItem("admin", admin);

      navigate("/home");
      await userService.getLoggedInUser();
    } catch (error) {
      console.error("Error:", error);
      alert("Authentication failed");
      navigate("/");
    }
  };

  const handleGoogleError = () => {
    console.log("Login Failed");
  };

  const handleMicrosoftLogin = async () => {
    try {
      const loginRequest = {
        scopes: ["openid", "profile", "User.Read"],
      };

      const loginResponse = await msalInstance.loginPopup(loginRequest);
      const microsoftToken = loginResponse.idToken;

      console.log("loginResponse", loginResponse);

      const response = await fetch(`${BASE_URL}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: microsoftToken, provider: "microsoft" }),
      });

      if (!response.ok) {
        throw new Error("Failed to authenticate with the backend");
      }

      const data = await response.json();
      const customJwt = data.jwt;
      const admin = data.admin;
      const { name, email } = loginResponse.account;

      localStorage.setItem("token", customJwt);
      localStorage.setItem("userName", name);
      localStorage.setItem("userEmail", email);
      localStorage.setItem("admin", admin);

      navigate("/patent-products");
      await userService.getLoggedInUser();
    } catch (error) {
      console.error("Error:", error);
      alert("Authentication failed");
      navigate("/");
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: handleGoogleError,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
        height: "250px",
        overflow: "hidden",
        margin: "auto",
        paddingTop: "40px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: 3,
          color: "primary.main",
        }}
      >
        Login
      </Typography>
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleError}
      />
      <Button
        variant="outlined"
        endIcon={
          <img
            src="https://img.icons8.com/color/48/000000/microsoft.png"
            alt="Microsoft logo"
            style={{ width: "20px" }}
          />
        }
        color="primary"
        onClick={handleMicrosoftLogin}
        sx={{
          marginTop: 2,
          color: "gray",
          fontSize: "11px",
          borderColor: "rgba(0, 0, 0, 0.23)",
        }}
      >
        Login with Microsoft
      </Button>
      {/* <Button
        variant="outlined"
        startIcon={<img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google logo" style={{ width: '20px' }} />}
        onClick={() => login()}
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          color: 'rgba(0, 0, 0, 0.54)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          textTransform: 'none',
          marginBottom: 1
        }}
      >
        Continue with Google
      </Button>
      <Button
        variant="outlined"
        startIcon={<img src="https://img.icons8.com/color/48/000000/microsoft.png" alt="Microsoft logo" style={{ width: '20px' }} />}
        onClick={handleMicrosoftLogin}
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          color: 'rgba(0, 0, 0, 0.54)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          textTransform: 'none'
        }}
      >
        Continue with Microsoft Account
      </Button> */}
    </Box>
  );
};

export default Login;
